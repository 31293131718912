@font-face {
	font-family: 'The-Village';
	src:url('fonts/The-Village.eot?-sl6kll');
	src:url('fonts/The-Village.eot?#iefix-sl6kll') format('embedded-opentype'),
		url('fonts/The-Village.woff?-sl6kll') format('woff'),
		url('fonts/The-Village.ttf?-sl6kll') format('truetype'),
		url('fonts/The-Village.svg?-sl6kll#The-Village') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'The-Village';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-close-thin:before {
	content: "\e69b";
}
.icon-ekk:before {
	content: "\e600";
}
.icon-on-the-city:before {
	content: "\e601";
}
.icon-mail1:before {
	content: "\e602";
}
.icon-plus:before {
	content: "\e603";
}
.icon-minus:before {
	content: "\e604";
}
.icon-quote-open1:before {
	content: "\e605";
}
.icon-ellipsis:before {
	content: "\e606";
}
.icon-info:before {
	content: "\e607";
}
.icon-reply-left:before {
	content: "\e608";
}
.icon-reply-right:before {
	content: "\e609";
}
.icon-refresh-left:before {
	content: "\e60a";
}
.icon-refresh-right:before {
	content: "\e60b";
}
.icon-transfer:before {
	content: "\e60c";
}
.icon-star:before {
	content: "\e60d";
}
.icon-home:before {
	content: "\e60e";
}
.icon-crosshair:before {
	content: "\e60f";
}
.icon-tag-close:before {
	content: "\e610";
}
.icon-printer:before {
	content: "\e611";
}
.icon-eject:before {
	content: "\e612";
}
.icon-forward:before {
	content: "\e613";
}
.icon-reverse:before {
	content: "\e614";
}
.icon-skip-forward:before {
	content: "\e615";
}
.icon-skip-back:before {
	content: "\e616";
}
.icon-stopwatch:before {
	content: "\e617";
}
.icon-clock:before {
	content: "\e618";
}
.icon-close:before {
	content: "\e619";
}
.icon-stop:before {
	content: "\e61a";
}
.icon-direct-up:before {
	content: "\e61b";
}
.icon-play:before {
	content: "\e61c";
}
.icon-direct-right:before {
	content: "\e61d";
}
.icon-nav-right:before {
	content: "\e61e";
}
.icon-direct-down:before {
	content: "\e61f";
}
.icon-direct-left:before {
	content: "\e620";
}
.icon-nav-left:before {
	content: "\e621";
}
.icon-target:before {
	content: "\e622";
}
.icon-record:before {
	content: "\e623";
}
.icon-cloud:before {
	content: "\e624";
}
.icon-heart:before {
	content: "\e625";
}
.icon-music:before {
	content: "\e626";
}
.icon-flag:before {
	content: "\e627";
}
.icon-gear:before {
	content: "\e628";
}
.icon-alert:before {
	content: "\e629";
}
.icon-caution:before {
	content: "\e62a";
}
.icon-plain:before {
	content: "\e62b";
}
.icon-mail2:before {
	content: "\e62c";
}
.icon-write:before {
	content: "\e62d";
}
.icon-erase:before {
	content: "\e62e";
}
.icon-check:before {
	content: "\e62f";
}
.icon-help:before {
	content: "\e630";
}
.icon-right:before {
	content: "\e631";
}
.icon-expand:before {
	content: "\e632";
}
.icon-left:before {
	content: "\e633";
}
.icon-up:before {
	content: "\e634";
}
.icon-down:before {
	content: "\e635";
}
.icon-up-right:before {
	content: "\e636";
}
.icon-up-left:before {
	content: "\e637";
}
.icon-down-right:before {
	content: "\e638";
}
.icon-down-left:before {
	content: "\e639";
}
.icon-cursor:before {
	content: "\e63a";
}
.icon-zoom-in:before {
	content: "\e63b";
}
.icon-zoom-out:before {
	content: "\e63c";
}
.icon-move:before {
	content: "\e63d";
}
.icon-write-disable:before {
	content: "\e63e";
}
.icon-trash:before {
	content: "\e63f";
}
.icon-tag:before {
	content: "\e640";
}
.icon-heart-half:before {
	content: "\e641";
}
.icon-star-half:before {
	content: "\e642";
}
.icon-dropper:before {
	content: "\e643";
}
.icon-crop:before {
	content: "\e644";
}
.icon-layers:before {
	content: "\e645";
}
.icon-fill:before {
	content: "\e646";
}
.icon-stroke:before {
	content: "\e647";
}
.icon-phone-disabled:before {
	content: "\e648";
}
.icon-rss:before {
	content: "\e649";
}
.icon-face-time:before {
	content: "\e64a";
}
.icon-send:before {
	content: "\e64b";
}
.icon-chat-ellipsis:before {
	content: "\e64c";
}
.icon-cart:before {
	content: "\e64d";
}
.icon-piechart:before {
	content: "\e64e";
}
.icon-graph:before {
	content: "\e64f";
}
.icon-navigate:before {
	content: "\e650";
}
.icon-compass:before {
	content: "\e651";
}
.icon-direction:before {
	content: "\e652";
}
.icon-map:before {
	content: "\e653";
}
.icon-location:before {
	content: "\e654";
}
.icon-database:before {
	content: "\e655";
}
.icon-hdd:before {
	content: "\e656";
}
.icon-airplay:before {
	content: "\e657";
}
.icon-pause:before {
	content: "\e658";
}
.icon-grid:before {
	content: "\e659";
}
.icon-rows:before {
	content: "\e65a";
}
.icon-columns:before {
	content: "\e65b";
}
.icon-thumbnails:before {
	content: "\e65c";
}
.icon-filter:before {
	content: "\e65d";
}
.icon-desktop:before {
	content: "\e65e";
}
.icon-tablet:before {
	content: "\e65f";
}
.icon-battery-high:before {
	content: "\e660";
}
.icon-battery-medium:before {
	content: "\e661";
}
.icon-battery-low:before {
	content: "\e662";
}
.icon-battery-empty:before {
	content: "\e663";
}
.icon-cloud-download:before {
	content: "\e664";
}
.icon-download:before {
	content: "\e665";
}
.icon-cloud-upload:before {
	content: "\e666";
}
.icon-upload:before {
	content: "\e667";
}
.icon-fork:before {
	content: "\e668";
}
.icon-merge:before {
	content: "\e669";
}
.icon-refresh:before {
	content: "\e66a";
}
.icon-loading:before {
	content: "\e66b";
}
.icon-wifi:before {
	content: "\e66c";
}
.icon-connection:before {
	content: "\e66d";
}
.icon-text:before {
	content: "\e66e";
}
.icon-font:before {
	content: "\e66f";
}
.icon-list:before {
	content: "\e670";
}
.icon-layout:before {
	content: "\e671";
}
.icon-action:before {
	content: "\e672";
}
.icon-contract:before {
	content: "\e673";
}
.icon-logout:before {
	content: "\e674";
}
.icon-dashboard:before {
	content: "\e675";
}
.icon-calendar-plus:before {
	content: "\e676";
}
.icon-calendar-minus:before {
	content: "\e677";
}
.icon-calendar-check:before {
	content: "\e678";
}
.icon-calendar-close:before {
	content: "\e679";
}
.icon-flask:before {
	content: "\e67a";
}
.icon-nav-up:before {
	content: "\e67b";
}
.icon-nav-down:before {
	content: "\e67c";
}
.icon-retweat:before {
	content: "\e67d";
}
.icon-photo:before {
	content: "\e67e";
}
.icon-globe:before {
	content: "\e67f";
}
.icon-mic:before {
	content: "\e680";
}
.icon-building:before {
	content: "\e681";
}
.icon-view:before {
	content: "\e682";
}
.icon-thumbs-up:before {
	content: "\e683";
}
.icon-thumbs-down:before {
	content: "\e684";
}
.icon-user:before {
	content: "\e685";
}
.icon-users:before {
	content: "\e686";
}
.icon-user-women:before {
	content: "\e687";
}
.icon-lightbulb:before {
	content: "\e688";
}
.icon-drop:before {
	content: "\e689";
}
.icon-chat:before {
	content: "\e68a";
}
.icon-dollar:before {
	content: "\e68b";
}
.icon-card:before {
	content: "\e68c";
}
.icon-screen:before {
	content: "\e68d";
}
.icon-suitcase:before {
	content: "\e68e";
}
.icon-folder:before {
	content: "\e68f";
}
.icon-file:before {
	content: "\e690";
}
.icon-calendar:before {
	content: "\e691";
}
.icon-barchart:before {
	content: "\e692";
}
.icon-pin:before {
	content: "\e693";
}
.icon-clip:before {
	content: "\e694";
}
.icon-notebook:before {
	content: "\e695";
}
.icon-book:before {
	content: "\e696";
}
.icon-book-open:before {
	content: "\e697";
}
.icon-compose:before {
	content: "\e698";
}
.icon-phone:before {
	content: "\e699";
}
.icon-print:before {
	content: "\e69a";
}
.icon-box:before {
	content: "\e69c";
}
.icon-newspaper:before {
	content: "\e69d";
}
.icon-cell:before {
	content: "\e69e";
}
.icon-camera:before {
	content: "\e69f";
}
.icon-video:before {
	content: "\e6a0";
}
.icon-shuffle:before {
	content: "\e6a1";
}
.icon-repeat:before {
	content: "\e6a2";
}
.icon-sound:before {
	content: "\e6a3";
}
.icon-sound-low:before {
	content: "\e6a4";
}
.icon-sound-high:before {
	content: "\e6a5";
}
.icon-battery:before {
	content: "\e6a6";
}
.icon-search:before {
	content: "\e6a7";
}
.icon-key:before {
	content: "\e6a8";
}
.icon-lock:before {
	content: "\e6a9";
}
.icon-unlock:before {
	content: "\e6aa";
}
.icon-bell:before {
	content: "\e6ab";
}
.icon-bell-off:before {
	content: "\e6ac";
}
.icon-bookmark:before {
	content: "\e6ad";
}
.icon-link:before {
	content: "\e6ae";
}
.icon-uni1F6AB:before {
	content: "\e6af";
}
.icon-uniE7B7:before {
	content: "\e6b0";
}
.icon-quotes-open2:before {
	content: "\e6b1";
}
.icon-quotes-close2:before {
	content: "\e6b2";
}
.icon-search-left:before {
	content: "\e1b7";
}
.icon-arrow-up:before {
	content: "\e6b3";
}
.icon-arrow-right:before {
	content: "\e6b4";
}
.icon-arrow-down:before {
	content: "\e6b5";
}
.icon-arrow-left:before {
	content: "\e6b6";
}
.icon-arrow-up2:before {
	content: "\e6b7";
}
.icon-arrow-right2:before {
	content: "\e6b8";
}
.icon-arrow-down2:before {
	content: "\e6b9";
}
.icon-arrow-left2:before {
	content: "\e6ba";
}
.icon-share:before {
	content: "\e6bb";
}
.icon-mail3:before {
	content: "\e6bc";
}
.icon-feed2:before {
	content: "\e6bd";
}
.icon-share-small:before {
	content: "\e6be";
}
.icon-facebook:before {
	content: "\e6bf";
}
.icon-twitter:before {
	content: "\e6c0";
}
.icon-linkedin:before {
	content: "\e6c1";
}
.icon-googleplus:before {
	content: "\e6c2";
}
.icon-appdotnet:before {
	content: "\e6c3";
}
.icon-zerply:before {
	content: "\e6c4";
}
.icon-reddit:before {
	content: "\e6c5";
}
.icon-stream:before {
	content: "\e6c6";
}
.icon-twitter2:before {
	content: "\e6c7";
}
.icon-wordpress:before {
	content: "\e6c8";
}
.icon-blogger:before {
	content: "\e6c9";
}
.icon-posterous:before {
	content: "\e6ca";
}
.icon-quora:before {
	content: "\e6cb";
}
.icon-youtube:before {
	content: "\e6cc";
}
.icon-vimeo:before {
	content: "\e6cd";
}
.icon-letterboxd:before {
	content: "\e6ce";
}
.icon-vine:before {
	content: "\e6cf";
}
.icon-flickr:before {
	content: "\e6d0";
}
.icon-instagram:before {
	content: "\e6d1";
}
.icon-500px:before {
	content: "\e6d2";
}
.icon-pinterest:before {
	content: "\e6d3";
}
.icon-svpply:before {
	content: "\e6d4";
}
.icon-redmill:before {
	content: "\e6d5";
}
.icon-dropbox:before {
	content: "\e6d6";
}
.icon-pinboard:before {
	content: "\e6d7";
}
.icon-delicious:before {
	content: "\e6d8";
}
.icon-dribble:before {
	content: "\e6d9";
}
.icon-behance:before {
	content: "\e6da";
}
.icon-github:before {
	content: "\e6db";
}
.icon-octocat:before {
	content: "\e6dc";
}
.icon-stackoverlow:before {
	content: "\e6dd";
}
.icon-paypal:before {
	content: "\e6de";
}
.icon-kickstarter:before {
	content: "\e6df";
}
.icon-etsy:before {
	content: "\e6e0";
}
.icon-foursquare:before {
	content: "\e6e1";
}
.icon-yelp:before {
	content: "\e6e2";
}
.icon-skype:before {
	content: "\e6e3";
}
.icon-rdio:before {
	content: "\e6e4";
}
.icon-spotify:before {
	content: "\e6e5";
}
.icon-lastfm:before {
	content: "\e6e6";
}
.icon-soundcloud:before {
	content: "\e6e7";
}
.icon-microsoft:before {
	content: "\e6e8";
}
.icon-windows:before {
	content: "\e6e9";
}
.icon-android:before {
	content: "\e6ea";
}
.icon-blackberry:before {
	content: "\e6eb";
}
.icon-apple:before {
	content: "\e6ec";
}
.icon-like:before {
	content: "\e6ed";
}
.icon-ss-phone:before {
	content: "\e6ee";
}
.icon-ss-link:before {
	content: "\e6ef";
}
.icon-logo-icon:before {
	content: "\e6f0";
}
.icon-logo:before {
	content: "\e6f1";
}
.icon-logo-text:before {
	content: "\e6f2";
}
